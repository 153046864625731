<template>
  <div v-if="view === selectedView || view === 'all'">
    <div class="sub-heading" v-if="view === 'all'" :class="{'hard': selectedView === 'hard'}">
      {{ selectedView === 'easy' ? '容易' : '困難' }}模式
    </div>
    <div class="no-record" v-if="scores.length === 0">未有挑戰者</div>
    <ul>
      <li class="scorelist__item" v-for="(score, index) in scores" :key="index">
        <div class="scorelist__item__name">
          <span :class="{'top': index < 3, 'hard': selectedView === 'hard' && index < 3}">{{ index + 1 }}</span>
          {{ score.username }}
          <span v-if="index < 3" class="medal" :class="{'first': index == 0, 'second': index == 1, 'third': index == 2}">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 49.94 49.94" style="enable-background:new 0 0 49.94 49.94;" xml:space="preserve">
              <path d="M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
                c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
                L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438
                c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701
                c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0
                l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904
                l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z"/>
            </svg>
          </span>
          <svg
            class="mepointer"
            v-if="score.username === user.username && !user.isAnonymous"
            :class="{'top': score.username === user.username && (selectedView === 'easy' ? (score.points === user.highestPointInEasyMode) : (score.points === user.highestPointInHardMode) )}"
            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 492 492" style="enable-background:new 0 0 492 492;" xml:space="preserve">
            <path d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
              c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
              L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
              c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
              c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
              C492,219.198,479.172,207.418,464.344,207.418z"/>
          </svg>
        </div>
        <div class="scorelist__item__points" :class="{'top': index < 3}">{{ score.points}}</div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "ScoreList",
  props: ['scores', 'selectedView', 'view', 'user'],
}
</script>

<style lang="scss" scoped>
.sub-heading {
  width: 100%;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: #fbc308;
  padding: .8rem 0;
  margin-bottom: 1rem;
  border-radius: 2rem;

  &.hard {
    background: #e74c3c;
    color: #fff;
  }
}

.scorelist {
  &__item {
    display: flex;
    font-size: 1rem;
    padding: .5rem 0;
    border-bottom: 1px solid #f1f1f1;
    align-items: center;

    &:hover {
        background: #f1f1f1;
    }

    &__name {
      flex-grow: 4;

      span:first-child {
        margin-right: .8rem;
        font-size: .8rem;
        padding: .2rem .4rem;
        border-radius: .2rem;
        
        &.top {
          background: #f6e58d;
          color:#f0932b;

          &.hard {
            background: #e74c3c;
            color: #fff;
          }
        }
      }
    }

    &__points {
      flex-grow: 1;
      color: #333;
      text-align: right;

      &.top {
        color: #eb4d4b;
      }
    }

    .mepointer {
      width: .8rem;
      height: .8rem;
      margin-left: .4rem;
      fill: #999787;

      &.top {
        
        fill: #fbc308;
        animation: moveleftright 500ms ease-out infinite;
      }
    }
  }
}

@keyframes moveleftright {
  0% { transform: translateX(0); }
  50%{ transform: translateX(.5rem); }
  100%{ transform: translateX(0); }
}

.no-record {
  width: 100%;
  text-align: center;
  font-size: 1rem; 
  padding-top: 2rem;
  flex-grow: 1;
}

.medal {
  svg {
    width: 1rem;
    height: 1rem;
    margin-left: 3px;
    transform: translateY(1px);
  }

  &.first {
    fill: #f9ca24;
  }

  &.second {
    fill: #95afc0;
  }

  &.third {
    fill: #967F6F;
  }
}
</style>
