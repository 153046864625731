<template>
  <div class="clock">
    <div class=clock__hours>
      <span class=clock__hourtime>{{hourtime}}</span>
      {{hours}}
    </div>
    <div class=clock__minutes>{{minutes}}</div>
    <div class=clock__seconds>{{seconds}}</div>
  </div>  
</template>

<script>
export default {
  name: 'Clock',
  data() {
    return {
      hours: '',
      minutes: '',
      seconds: '',
      hourtime: ''
    }
  },
  created() {
    this.updateDateTime();
  },
  methods: {
    updateDateTime() {
      var self = this;
      var now = new Date();

      self.hours = now.getHours();
      self.minutes = self.getZeroPad(now.getMinutes());
      self.seconds = self.getZeroPad(now.getSeconds());
      self.hourtime = self.getHourTime(self.hours);
      self.hours = self.hours % 12 || 12;

      setTimeout(self.updateDateTime, 1000);
    },

    getHourTime(h) {
      return h >= 12 ? 'PM' : 'AM';
    },

    getZeroPad(n) {
      return (parseInt(n, 10) >= 10 ? '' : '0') + n;
    }
  }
}
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Nunito);
.clock {
  background: #fff;
  border: .3rem solid #fff;
  border-radius: .5rem;
  display: flex;
}

.clock__hours,
.clock__minutes,
.clock__seconds {
  background: linear-gradient(to bottom, #26303b 50%, #2c3540 50%);
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  padding: .3rem .8rem;
  text-align: center;
  position: relative;
}

.clock__hours {
  border-right: .15rem solid #fff;
  border-radius: .7rem 0 0 .7rem;
}
.clock__minutes {
  border-right: .15rem solid #fff;
}
.clock__seconds {
  border-radius: 0 .7rem .7rem 0;
}

.clock__hourtime {
  font-size: .5rem;
  position: absolute;
  top: 2px;
  left: 8px;
}
</style>
