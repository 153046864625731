<template>
  <Scoreboard />
</template>

<script>
import Scoreboard from './components/Scoreboard.vue'

export default {
  name: 'app',
  components: {
    Scoreboard 
  }
}
</script>

<style>
</style>
