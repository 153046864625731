<template>
  <div class="sb-container">
    <div id="refreshBtn" @click="fetchScore" :class="{'is-loading': isloading }">
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 489.533 489.533" style="enable-background:new 0 0 489.533 489.533;" xml:space="preserve">
        <path d="M268.175,488.161c98.2-11,176.9-89.5,188.1-187.7c14.7-128.4-85.1-237.7-210.2-239.1v-57.6c0-3.2-4-4.9-6.7-2.9
          l-118.6,87.1c-2,1.5-2,4.4,0,5.9l118.6,87.1c2.7,2,6.7,0.2,6.7-2.9v-57.5c87.9,1.4,158.3,76.2,152.3,165.6
          c-5.1,76.9-67.8,139.3-144.7,144.2c-81.5,5.2-150.8-53-163.2-130c-2.3-14.3-14.8-24.7-29.2-24.7c-17.9,0-31.9,15.9-29.1,33.6
          C49.575,418.961,150.875,501.261,268.175,488.161z"/>
      </svg>
    </div>
    <div id="anonymousBtn" :class="{'anonymous': user.isAnonymous}" @click="toggleAnonymous">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 124.72"><title>anonymous</title>
        <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
        <path d="M99.88,41.8c0,6-.2,11.94.05,17.9A52.94,52.94,0,0,1,95.4,82.77C89,97.92,79.65,110.65,65.19,119.06c-3,1.74-6.31,3-9.47,4.42-4,1.83-7.82,1.48-11.89,0A63,63,0,0,1,15.64,102.2,77.08,77.08,0,0,1,.58,69.49a36.39,36.39,0,0,1-.47-6.44C.07,48.34.24,33.63,0,18.92c-.07-4.54,1.67-7.72,5.4-9.54A72.07,72.07,0,0,1,19.62,3.72,126.15,126.15,0,0,1,81,3.85,65.63,65.63,0,0,1,93.52,8.72c4.36,2.12,6.74,5.52,6.46,10.86C99.6,27,99.88,34.39,99.88,41.8ZM12.54,68l-.52.4L24.88,89H45l5.08-5.75L54.92,89H75.11L88,68.34l-.5-.36-15,12.46H60.3l-7.25-8H46.66l-7.33,8H27.52Zm-.25-32.74c1.78-.93,3.51-2,5.34-2.76,7.69-3.42,12.76-2,17.51,4.94a29.23,29.23,0,0,0,2.11,3,4,4,0,0,0,5.3.64C44.24,40,45.13,37.7,44,36c-3.25-5-7-9.42-13.32-10.59C23.21,24,16.6,27.4,12.29,35.28Zm75.37-.05C84.32,29,79.32,25,72.07,25.08c-7.47.06-12.16,4.83-16,10.58a4,4,0,0,0,1.26,5.42,3.9,3.9,0,0,0,5.48-.79c.9-1.11,1.57-2.4,2.42-3.56,4.42-6,9.24-7.37,16.14-4.57C83.54,33,85.57,34.2,87.66,35.23Zm-32.19,78.3V100.35h-11v13.58L50,119.61ZM14.08,46.05c4.69,5.28,16.34,5.69,21.79.05C31.35,40.32,18.61,40.34,14.08,46.05Zm71.9,0c-5.6-6.32-18.6-5.12-21.8.13A16.6,16.6,0,0,0,86,46.08Z"/>
        </g></g>
        </svg>
      <span>{{ user.isAnonymous ? '隱身開啟' : '隱身關閉'}}</span>
    </div>
    <div class="sb-wrapper">
      <div class="sb-header">
        <div class="sb-header__title">
          <div class="rankicon"/>排行榜
        </div>
        <div class="sb-header__subtitle">
          <span v-if="displayTitleWhenScrolling" class="easy">容易模式</span>
          <span class="desc">最近30分鐘刷新嘅紀錄</span>
          <span v-if="displayTitleWhenScrolling" class="hard">困難模式</span>
        </div>
      </div>

      <div class="sb-content">
        <div class="scorelist__header">
          <div class="scorelist__header--easy" @click="toggleView('easy')" :class="{'is-active': view === 'easy'}" >容易</div>
          <div class="scorelist__header--hard" @click="toggleView('hard')" :class="{'is-active': view === 'hard'}" >困難</div>
          <div class="scorelist__header--all" @click="toggleView('all')" :class="{'is-active': view === 'all'}">全部</div>
        </div>
        <div ref="scorelist" class="scorelist__content" v-if="!isloading" @scroll="handleScrollingToDisplayTitle">
          <ScoreList :view="view" selectedView="easy" :scores="scoreEasy" :user="user"/>
          <ScoreList :view="view" selectedView="hard" :scores="scoreHard" :user="user"/>
        </div>
        <div v-else class="loadingDog" >
          <img alt="" src="https://lihkg.com/assets/faces/dog/run.gif" />
          <img alt="" src="https://lihkg.com/assets/faces/dog/run.gif" />
          <img alt="" src="https://lihkg.com/assets/faces/dog/run.gif" />
        </div>
      </div>

      <div class="sb-footer">
        <div><Clock /></div>
        <div>紀錄可能稍有延遲</div>
      </div> 
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ScoreList from './ScoreList'
import Clock from './Clock'

export default {
  name: "Ranking",
  components: {
    ScoreList,
    Clock
  },
  data() {
    return {
      scoreEasy: [],
      scoreHard: [],
      isClose: false,
      view: 'all',
      isloading: false,
      displayTitleWhenScrolling: false,
      user: {
        isAnonymous: true,
        username: '',
        highestPointInEasyMode: '',
        highestPointInHardMode: '',
      }
    }
  },
  methods: {
    toggleView(viewName) {
      this.view = viewName
    },
    toggleAnonymous() {
      this.user.isAnonymous = !this.user.isAnonymous
    },
    handleResize() {
      if (window.innerWidth <= 600) {
        this.view = 'easy'
      }
      if (window.innerWidth >= 601) {
        this.view = 'all'
      }
    },
    handleScoreSorting() {
      this.scoreEasy = this.scoreEasy.sort((a, b) => {
        return b.points - a.points
      })
      this.scoreHard = this.scoreHard.sort((a, b) => {
        return b.points - a.points
      })
    },
    handleScrollingToDisplayTitle() {
      if (this.$refs.scorelist.scrollTop > 58 && this.view === 'all') {
        this.displayTitleWhenScrolling = true
      } else {
        this.displayTitleWhenScrolling = false
      }
    },
    async fetchScore() {
      this.isloading = true

      // fetch api
      await axios.get('https://rd-game.lihkg.com/easy', )
        .then(response => {
          this.scoreEasy = response.data.data
        })
        .catch(() => {})

      await axios.get('https://rd-game.lihkg.com/hard', )
        .then(response => {
          this.scoreHard = response.data.data
        })
        .catch(() => {})


      // window location query
      const locationQuery = window.location.search.replace(/^\?/, '').split('&').reduce(function (acc, part) {
        var parts = part.split('=')
          var obj = {}
          obj[parts[0]] = decodeURIComponent(parts[1])
          return Object.assign({}, acc, obj)
      }, {});

      // get the highest point of the user 
      if (locationQuery.username) {
        const fetchedUsername = locationQuery.username
        this.user.username = fetchedUsername
        
        // easy
        const userArrayEasy = this.scoreEasy.filter(score => score.username === fetchedUsername)
        this.user.highestPointInEasyMode = Math.max(...userArrayEasy.map(score => score.points), 0).toString();

        // hard
        const userArrayHard = this.scoreHard.filter(score => score.username === fetchedUsername)
        this.user.highestPointInHardMode = Math.max(...userArrayHard.map(score => score.points), 0).toString();
      } 
      this.isloading = false
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.handleScoreSorting()
    // const scoreModal = document.getElementById('navRanking')
    // scoreModal.addEventListener('click', () => {
    //     this.fetchScore()
    // })
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped lang="scss">
.sb-container {
  background-color: white;
  height: 100%;
  border-radius: 2rem;
  font-size: 62.5%;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0 1rem;
  position: relative;
  overflow: auto;
}

#refreshBtn {
  position: absolute;
  float: right;
  right: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: #666 !important;
    z-index: 99;
    cursor: pointer;
    transition: all 100ms ease-in-out;

    &:hover {
      fill: #333 !important;
      transform: scale(1.2);
    }
  }
}

#anonymousBtn {
  position: absolute;
  float: right;
  right: 1rem;
  margin-right: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #a5a5a5;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &.anonymous {
    color: #e74c3c;
    svg {
      fill: #e74c3c;
      animation: swing ease-in-out 500ms infinite alternate;
    }
  }

  svg {
    width: 1.3rem;
    fill: #a5a5a5;
  }

  span {
    margin-top: .2rem;
    font-size: .6rem;
  }
}

@keyframes swing {
  0% { transform: rotate(10deg); }
  100% { transform: rotate(-10deg); }
}

.sb-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  // scoreboard header
  .sb-header {
    text-align: center;
    padding-top: .5rem;
    margin-bottom: 1rem;
    height: 5rem;

    &__title {
      font-size: 2rem;
      font-weight: bold;
      color: #000;

      .rankicon {
        display: inline-block;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ4My44IDQ4My44IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0ODMuOCA0ODMuODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxnPjxwYXRoIGQ9Ik0xMjMuMiwxMzRIMTUuN0M3LDEzNCwwLDE0MSwwLDE0OS43djMxMC42QzAsNDY5LDcsNDc2LDE1LjcsNDc2aDEwNy41YzguNywwLDE1LjctNywxNS43LTE1LjdWMTQ5LjdDMTM5LDE0MSwxMzEuOSwxMzQsMTIzLjIsMTM0eiBNMTA3LjUsNDQ0LjZIMzEuNFYxNjUuNGg3Ni4xVjQ0NC42eiIvPjxwYXRoIGQ9Ik00NjguMSwyMTYuNEgzNjAuNmMtOC43LDAtMTUuNyw3LTE1LjcsMTUuN3YyMjguMmMwLDguNyw3LDE1LjcsMTUuNywxNS43aDEwNy41YzguNywwLDE1LjctNywxNS43LTE1LjdWMjMyLjFDNDgzLjgsMjIzLjQsNDc2LjgsMjE2LjQsNDY4LjEsMjE2LjR6IE0zNzYuMywyNDcuOGgyNC40bC0yNC40LDI0LjRWMjQ3Ljh6IE0zNzYuMywyOTcuMmw0OS4zLTQ5LjNoMjQuOWwtNzQuMiw3NC4yTDM3Ni4zLDI5Ny4yTDM3Ni4zLDI5Ny4yeiBNNDUyLjQsNDQ0LjZoLTI0LjVsMjQuNS0yNC41VjQ0NC42eiBNNDUyLjQsMzk1LjJMNDAzLDQ0NC42aC0yNC45bDc0LjItNzQuMnYyNC44SDQ1Mi40eiBNNDUyLjQsMzQ1LjVsLTc2LjEsNzYuMXYtMjQuOWw3Ni4xLTc2LjFWMzQ1LjV6IE00NTIuNCwyOTUuN2wtNzYuMSw3Ni4xdi0yNC45bDc2LjEtNzYuMVYyOTUuN3oiLz48cGF0aCBkPSJNMjk1LjcsNy44SDE4OC4xYy04LjcsMC0xNS43LDctMTUuNywxNS43djQzNi44YzAsOC43LDcsMTUuNywxNS43LDE1LjdoMTA3LjVjOC43LDAsMTUuNy03LDE1LjctMTUuN1YyMy41QzMxMS40LDE0LjgsMzA0LjQsNy44LDI5NS43LDcuOHoiLz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==);
        height: 1.8rem;
        width: 1.8rem;
        margin-right: 5px;
        transform: translateY(3px);
      }
    }

    &__subtitle {
      display: flex;
      justify-content: center;

      .easy {
        background: #fbc308;
        color: #333;
      }

      .hard {
        background: #e74c3c;
        color: #fff;
      }
    }

    span:not(.desc) {
      font-size: 1rem;
      padding: .3rem 1rem;
      font-weight: bold;
      border-radius: 1rem;
    }

    span.desc {
      font-size: .8rem;
      color: #777;
      margin: 0 2rem;
    }
  }

  // score board content 
  .sb-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .scorelist__header {
      display: flex;
      margin-bottom: 1rem;
      font-weight: bold;
      cursor: pointer;
      
      > div {
        transition: all 200ms ease-in;
        height: 2.5rem;
      }

      :hover:not(.is-active) {
        transform: scale(1.2);
      }
      
      > div {
        flex-grow: 1;
        padding: .5rem 2rem;
        font-size: 1rem;
        text-align: center;
        margin: 0 1rem;

        &.is-active {
          background: #fbc308;
          border-radius: 2rem;
        }
      }

      &--hard.is-active {
        background: #e74c3c !important;
        color: #fff !important;
      }
    }

    .scorelist__content {
      display: flex;
      flex-grow: 1;
      height: 100%;

      overflow: auto;
      -webkit-overflow-scrolling: touch;

      > div {
        flex-grow: 1;
        margin: 0 1.4rem;
        width: 100%;
      }
    }

    @media (min-width: 601px) {
      .scorelist__header {
        display: none;
      }
    }

  }
  // footer
  .sb-footer {
    height: 5rem;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;  
  }
}

@media (max-width: 600px) {
  .sb-container {
    padding: .5rem !important;
  }

  .sb-header {
    height: 4rem !important;
    margin-bottom: .5rem !important;

    &__title {
      font-size: 1.4rem !important;
      .rankicon {
        width: 1.3rem !important;
        height: 1.3rem !important;
      }
    }
  }

  .sb-content {
    .scorelist__header {
      margin-bottom: .5rem !important;

      > div {
        margin: 0 .5rem !important;
      }
    }

    .scorelist__content {

      > div {
        margin: 0 .5rem !important;
      }
    }
  }

  .scorelist__header--all {
    display: none;
  }
}

.is-loading {
  animation: spin 1s infinite linear;
}

.loadingDog {
  flex-grow: 1;
  text-align: center;
  margin-top: 3rem;
}


@keyframes spin {
  from { 
      transform: rotate(0deg); 
  } to { 
      transform: rotate(-360deg); 
  }
}

</style>
